<template>
  <v-theme-provider>
    <base-section
      id="k-d-a-business-card"
      class="background-primary-gradient white--text"
      space="24"
    >
      <v-container>
        <k-d-a-business-card-row
          :str-title="m_strBusinessCardTitle"
          str-text-color="white"
        >
          {{ m_strBusinessCardText }}

          <br>
          <div class="my-2" />
          <br>

          <base-btn
            color="white"
            :to="{ name: 'KDAPortfolio' }"
          >
            {{ m_strButtonLabel }}
          </base-btn>
        </k-d-a-business-card-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import BusinessDetails from '@/mixins/business-details'

  export default {
    name: 'KDABusinessCard',

    components: {
      KDABusinessCardRow: () => import('@/components/KDABusinessCardRow'),
    },

    mixins: [BusinessDetails],

    data: () => ({
      m_strBusinessCardTitle: 'ABOUT US',
      m_strButtonLabel: 'More Info',
    }),

    computed: {
      m_strBusinessCardText () {
        return this.g_strBusinessDescriptionShort
      },
    },
  }
</script>

<style lang="sass">
  .background-primary-gradient
    background: radial-gradient(closest-side, $accent2, $accent1)
</style>
